<p-toast [baseZIndex]="1000"></p-toast>

<div class="header">
   <div class="bg-blue-50 flex">
      <div class="w-4 flex align-items-center justify-content-start p-4">
         <div *ngFor="let h of headerService.headerLeftDirective">
            <ng-container *ngTemplateOutlet="h.templateRef"></ng-container>
         </div>
      </div>
      <div class="w-4 flex flex-grow-1 align-items-center justify-content-center">
         <img src="assets/youskill-logo.png" style="height: 30px" class="m-4" alt="youskill" />
      </div>
      <div class="w-4 flex flex-grow-1 align-items-center justify-content-end p-4">
         <div *ngFor="let h of headerService.headerRightDirective">
            <ng-container *ngTemplateOutlet="h.templateRef"></ng-container>
         </div>
         <div class="pl-3">
            <avatar [options]="{ menu: true }"></avatar>
         </div>
      </div>
   </div>
</div>

<div class="content">
   <div class="flex flex-column h-full">
      <div class="relative flex-1 overflow-scroll"><router-outlet></router-outlet></div>
      <div class="m-3 text-right">
         <footer-component></footer-component>
      </div>
   </div>
</div>
