import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { AvatarAppModule } from '../avatar/avatar.module';
import { AppTemplateComponent } from './app-template.component';
import { HeaderService } from './header/header.service';
import { CommonKitModule } from '@app/app/common-kit.module';

const declarations = [AppTemplateComponent];

@NgModule({
   declarations: declarations,
   imports: [CommonModule, ToastModule, AvatarAppModule, RouterModule, CommonKitModule],
   providers: [HeaderService],
})
export class AppTemplateModule {}
