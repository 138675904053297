import { Component } from '@angular/core';

@Component({
   selector: 'footer-component',
   template: `<div class="text-xs text-500"
      >© 2024 Fullstack · (v1.0.0-DEV) · By Mondo a Colori Media Network S.R.L. · PIVA 08965661005 | Privacy policy -
      Termini e condizioni</div
   >`,
   providers: [],
})
export class FooterComponent {}
